
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import FormsReportTypes from '@/components/FormsReportTypes.vue';

export default defineComponent({
    components: { FormsReportTypes },
    mixins: [Base],
    emits: ['reload'],
    data() {
        return {
            item: {
                translations: {
                    name: {},
                },
            },
        };
    },
    methods: {
        createItem(): void {
            console.log(this.item.translations.name);
            this.post('infringement-categories', {
                ...this.item,
                name: this.item.translations.name,
                forms_report_types: this.item.forms_report_types.map((f) => f.id),
            }).then(() => {
                this.$router.push({ name: 'infringements.categories.list' });
                this.$emit('reload');
            });
        },
    },
});
